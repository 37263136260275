import React from "react";
import { Row, Col } from "react-bootstrap";
import "../App.css";

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
            < hr />
      <div className="container">
  
        <Row className="justify-content-md-center">
          <Col md="auto">
            <span className="text-muted pull-right">
              <a target="_blank" rel="noopener noreferrer" href="https://wiki.docking.org/index.php?title=Chemistry_commons">
                About
              </a>
              &nbsp;|&nbsp;
              <a target="_blank" rel="noopener noreferrer" href="https://forms.gle/LZV1FCmLWxUWznVi9">Contact Us</a>
              &nbsp;|&nbsp;
              <a target="_blank" rel="noopener noreferrer" href="https://wiki.docking.org/index.php/Privacy_policy">
                Privacy Policy
              </a>
              &nbsp;|&nbsp;
              <a target="_blank" rel="noopener noreferrer" href="https://wiki.docking.org/index.php/Terms_And_Conditions">
                Terms of Use
              </a>
              &nbsp;|&nbsp; © {year} <a target="_blank" rel="noopener noreferrer" href="https://irwinlab.compbio.ucsf.edu/">Irwin</a> and <a target="_blank" rel="noopener noreferrer" href="https://bkslab.org/">Shoichet</a> Labs and the UC Regents
            </span>
            <p className="small muted">This is not an official UCSF website. The opinions or statements expressed herein should not be taken as a position of or endorsement by the University of California, San Francisco.</p>
          </Col>
        </Row>
      </div>
    </footer>
  );
}
