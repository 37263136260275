import React, { useState, useEffect } from "react";
import axios from "axios";
import RxnTable from "../layout/components/RxnTable";
import { Button, Modal, Form } from "react-bootstrap";
import { IconAddOutline } from "../layout/components/SVGs";
import toast from "react-hot-toast";

// Authentication Check
import useToken from "../authentication/token";
import { useNavigate } from "react-router-dom";

export default function Roles() {
  // Authentication Check
  const { token, role, verifyToken, kickOutGuest } = useToken();
  const navigate = useNavigate();
  useEffect(() => {
    if (role !== "admin") {
      navigate("/");
    }
    token ? verifyToken() : kickOutGuest();
  }, []);

  const [roleList, setRoleList] = useState({
    columns: [
      {
        label: "ID",
        field: "role_id",
      },
      {
        label: "Role",
        field: "role_name",
      },
      {
        label: "Description",
        field: "description",
      },
    ],
    data: [],
  });

  const [userRole, setUserRole] = useState({
    id: "",
    name: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);

  const [showAddRole, setShowAddRole] = useState(false);
  const handleAddClose = () => setShowAddRole(false);
  const handleShowAdd = () => {
    setShowAddRole(true);
  };

  const [showEditRole, setShowEditRole] = useState(false);
  const handleEditClose = () => {
    setShowEditRole(false);
    setUserRole({ id: "", name: "", description: "" });
  };
  const handleShowEdit = (row) => {
    setShowEditRole(true);
    setUserRole({
      id: row.role_id,
      name: row.role_name,
      description: row.description,
    });
  };

  const [showDeleteRole, setShowDeleteRole] = useState(false);
  const handleDeleteClose = () => {
    setShowDeleteRole(false);
    setUserRole({ id: "", name: "", description: "" });
  };
  const handleShowDelete = (row) => {
    setShowDeleteRole(true);
    setUserRole({
      id: row.role_id,
      name: row.role_name,
    });
  };

  function getRoleList() {
    axios({
      method: "GET",
      url: "/admin/manage/roles_list",
    })
      .then((res) => {
        setRoleList({ ...roleList, data: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleSubmitCreate() {
    setLoading(true);
    let roleData = {
      roleName: userRole.name,
      roleDescription: userRole.description,
    };
    console.log(roleData);
    axios({
      method: "POST",
      url: "/admin/manage/roles/add",
      data: roleData,
    })
      .then((res) => {
        getRoleList();
        toast.success(res.data.msg);
        handleAddClose();
      })
      .catch((err) => {
        handleAddClose();
        toast.error(err.response.data.msg);
      });
    setLoading(false);
  }

  function handleSubmitEdit() {
    setLoading(true);
    let roleData = {
      roleId: userRole.id,
      roleName: userRole.name,
      roleDescription: userRole.description,
    };
    axios({
      method: "POST",
      url: "/admin/manage/roles/update",
      data: roleData,
    })
      .then((res) => {
        getRoleList();
        toast.success(res.data.msg);
        handleEditClose();
      })
      .catch((err) => {
        handleEditClose();
        toast.error(err.response.data.msg);
      });
    setLoading(false);
  }

  function handleSubmitDelete() {
    setLoading(true);
    let roleData = {
      roleId: userRole.id,
    };
    axios({
      method: "POST",
      url: "/admin/manage/roles/delete",
      data: roleData,
    })
      .then((res) => {
        getRoleList();
        toast.success(res.data.msg);
        handleDeleteClose();
      })
      .catch((err) => {
        handleDeleteClose();
        toast.error(err.response.data.msg);
      });
    setLoading(false);
  }

  useEffect(() => {
    getRoleList();
  }, []);

  return (
    <>
      <RxnTable
        striped
        bordered
        hover
        data={roleList}
        token={token}
        actions={{
          add: true,
          addLogic: () => (
            <Button
              onClick={() => {
                handleShowAdd();
              }}
            >
              <IconAddOutline />
            </Button>
          ),
          edit: true,
          editLogic: (row) => {
            handleShowEdit(row);
          },
          delete: true,
          deleteLogic: (row) => {
            handleShowDelete(row);
          },
        }}
      ></RxnTable>

      {/* 
      ***
      Modal for creating roles
      ***
       */}
      <Modal
        size="xl"
        backdrop="static"
        show={showAddRole}
        onHide={handleAddClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter role name"
                onChange={(e) => {
                  setUserRole({ ...userRole, name: e.target.value });
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Role Description</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter role description"
                onChange={(e) =>
                  setUserRole({ ...userRole, description: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="primary"
            type="submit"
            onClick={() => handleSubmitCreate()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        backdrop="static"
        show={showEditRole}
        onHide={handleEditClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter role name"
                value={userRole.name}
                onChange={(e) =>
                  setUserRole({ ...userRole, name: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Role Description</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter role description"
                value={userRole.description}
                onChange={(e) =>
                  setUserRole({ ...userRole, description: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="primary"
            type="submit"
            onClick={() => handleSubmitEdit()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        backdrop="static"
        show={showDeleteRole}
        onHide={handleDeleteClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete "{userRole.name}"
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="danger"
            type="submit"
            onClick={() => handleSubmitDelete()}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
